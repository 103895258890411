import Image from 'next/future/image';
import Link from 'next/link';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import HeaderFooterContext from '@/context/HeaderFooterContext';
import faceBookIcon from '@/public/images/icons/facebook-icon.svg';
import instagramIcon from '@/public/images/icons/instagram-icon.svg';
import linkedInIcon from '@/public/images/icons/linkedin-icon.svg';
import twitterIcon from '@/public/images/icons/x-twitter-icon.svg';
import youTubeIcon from '@/public/images/icons/youtube-icon.svg';

import style from './social.module.scss';

const SocialLinks = () => {
  const headerFooterData: any = useContext(HeaderFooterContext);
  const { headerFooter } = headerFooterData;

  const socialURLS =
    headerFooter &&
    headerFooter[0] &&
    headerFooter[0].attributes &&
    headerFooter[0].attributes.Social_Media;

  const faceBookUrl = socialURLS?.Facebook_Url;
  const instagramUrl = socialURLS?.Instagram_Url;
  const linkedInUrl = socialURLS?.LinkedIn_Url;
  const twitterUrl = socialURLS?.Twitter_Url;
  const youtubeUrl = socialURLS?.Youtube_Url;

  return (
    <div className="social-links">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className={style.arrange}>
            <span className="follow-us">
              <FormattedMessage id="footer.follow-us" />:
            </span>
            <div className="icons-row d-flex">
              {faceBookUrl && (
                <div className="social-icon fb-icon">
                  <Link href={faceBookUrl} prefetch={false}>
                    <a target="_blank">
                      <Image src={faceBookIcon} alt="facebook" />
                    </a>
                  </Link>
                </div>
              )}
              {instagramUrl && (
                <div className="social-icon">
                  <Link href={instagramUrl} prefetch={false}>
                    <a target="_blank">
                      <Image src={instagramIcon} alt="instagram" />
                    </a>
                  </Link>
                </div>
              )}
              {linkedInUrl && (
                <div className="social-icon">
                  <Link href={linkedInUrl} prefetch={false}>
                    <a target="_blank">
                      <Image src={linkedInIcon} alt="linkedin" />
                    </a>
                  </Link>
                </div>
              )}
              {twitterUrl && (
                <div className="social-icon">
                  <Link href={twitterUrl} prefetch={false}>
                    <a target="_blank">
                      <Image src={twitterIcon} alt="twitter" />
                    </a>
                  </Link>
                </div>
              )}
              {youtubeUrl && (
                <div className="social-icon">
                  <Link href={youtubeUrl} prefetch={false}>
                    <a target="_blank">
                      <Image src={youTubeIcon} alt="youtube" />
                    </a>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialLinks;
